// @import './Style.scss';
$color-paid: #d5bbfe;
@mixin border-shadow-radius($border, $shadow, $radius) {
  border: $border;
  box-shadow: $shadow;
  border-radius: $radius;
}
@mixin scroll-bar {
  &::-webkit-scrollbar {
    width: 6.25px;
  }
  &::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px #e6e6e6;
  }
  &::-webkit-scrollbar-thumb {
    @include border-shadow-radius(solid #e6e6e6 5px, -item, 999px);
    background-clip: padding-box;
  }
}
.nbr-container {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 42px;
}
// .nbr-header {
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 29px;
//   color: #333333;
//   position: static;
//   a {
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 21px;
//     text-align: center;
//     color: #3e72db;
//     text-decoration: none;
//   }
// }
.header-over {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}
.active-project-status {
  width: 100%;
  margin-top: 8rem;
  background-color: #e6e6e6;
  .active-project-bar {
    // padding: 17px 22px;
    .active-project-bar-chart {
      padding: 17px 22px;
      overflow-x: scroll;
      height: 183px;
      background: #ffffff;
      margin-bottom: 0.5rem;
      p {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: #333333;
      }
    }
    .active-project-bar-bar {
      margin-top: 17px;
      padding: 10px 20px;
      background: #ffffff;
      height: 135px;
      overflow: scroll;
      .active-project-bar-cn {
        padding: 12px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .project-bar-status {
          border-right: 0.5px solid #b3b3b3;
          .project-bar-one {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #6f727a;
          }
          &:nth-child(4) {
            border-right: none;
            outline: none;
          }
          .project-bar-two {
            font-weight: 700;
            font-size: 24px;
            line-height: 44px;
            color: #232325;
          }
        }
      }
    }
  }
  .active-project-curve {
    background-color: #fff;
    padding: 17px 22px;
    height: 335.5px;
    min-width: 24% !important;
    canvas {
      height: 285px;
    }
  }
}

.main-card-header {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}
.month-card-container {
  overflow: scroll;
  @include scroll-bar();
  height: 34vh;
  column-gap: 35px;
  row-gap: 16px;
  .month-card {
    .month-card-header {
      height: 36px;
      background: #e6e6e6;
      border-radius: 6px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      padding: 8px 8px;
    }
    padding: 15px 14px;
    height: 190px;
    border: 0.5px solid #b3b3b3;
    box-shadow: 0px 2px 4px -2px rgba(51, 51, 51, 0.16),
      0px 4px 8px rgba(51, 51, 51, 0.08), 0px 0px 0px 1px rgba(51, 51, 51, 0.04);
    border-radius: 6px;
    .header-header-sub {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #888888;
    }
    .header-value-sub {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }
  }
}

//bar chart$color-paid: #D5BBFE;
$color-sent: #39cccc;

.active-bar-chart-chart-a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'fira-sans-2', Verdana, sans-serif;
  .active-bar-header {
    position: absolute;
    top: -1rem;
    left: 1.2rem;
  }
}

#q-graph {
  display: block;
  position: relative;
  width: 85%;
  height: 125px;
  padding: 0;
  background: transparent;
  font-size: 11px;
  border-bottom: 0.5px solid #b3b3b3;
}

#q-graph tr,
#q-graph th,
#q-graph td {
  position: absolute;
  bottom: 0;
  width: 161px;
  z-index: 2;
  display: flex;
  justify-content: center;
  text-align: center;
}

#q-graph td {
  transition: all 0.3s ease;
  &:hover {
    background-color: desaturate(#85144b, 100);
    opacity: 0.9;
    color: white;
  }
}

#q-graph tbody tr {
  padding-top: 2px;
  border-right: 1px dotted #c4c4c4;
  color: #aaa;
}

#q-graph #q2 {
  left: 9rem;
}
#q-graph #q3 {
  left: 18rem;
}
#q-graph #q4 {
  left: 27rem;
}
#q-graph #q5 {
  left: 36rem;
}
#q-graph #q6 {
  left: 45rem;
  border-right: none;
}

#q-graph th {
  bottom: -1.5em;
  vertical-align: top;
  font-weight: 600;
  color: #333;
}
#q-graph .bar {
  width: 50%;
  border: 1px solid;
  border-bottom: none;
  color: #000;
}

#q-graph .paid {
  left: 47px;
  background-color: $color-paid;
  border-color: transparent;
}

.nbr-download {
  padding: 6px 12px;
  border: 1px solid #3e72db;
  display: flex;
  align-items: center;
  border-radius: 7px;
  svg {
    margin-right: 6px;
  }
}
@media screen and (min-width: 1340px) {
  #q-graph #q2 {
    left: 9rem;
  }
  #q-graph #q3 {
    left: 18rem;
  }
  #q-graph #q4 {
    left: 27rem;
  }
  #q-graph #q5 {
    left: 36rem;
  }
  #q-graph #q6 {
    left: 45rem;
    border-right: none;
  }
  #q-graph tr,
  #q-graph th,
  #q-graph td {
    width: 242px;
  }
  .active-bar-chart-chart-a .active-bar-header {
    left: 2.3rem;
  }
  #q-graph .bar {
    width: 55%;
  }
}
.header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
}
.download-report {
  display: flex;
  align-items: center;
  border: 1px solid #3e72db;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  svg{
    margin-right: 6px;
  }
  a {
    cursor: pointer;
    color: #3E72DB;
    text-decoration: none;
  }
}