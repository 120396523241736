@mixin border-shadow-radius($border, $shadow, $radius) {
  border: $border;
  box-shadow: $shadow;
  border-radius: $radius;
}

@mixin scroll-bar {
  &::-webkit-scrollbar {
    width: 6.25px;
  }
  &::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px #e6e6e6;
  }
  &::-webkit-scrollbar-thumb {
    @include border-shadow-radius(solid #e6e6e6 5px, -item, 999px);
    background-clip: padding-box;
  }
}

.header-container {
  position: relative;
  padding: 0px 4rem;
}
.tab-header {
  position: absolute;
  top: 0.5rem;
  .MuiTabs-indicator {
    color: #336cd9 !important;
    background-color: #336cd9 !important;
  }
}
.project-creation-button {
  position: absolute;
  .MuiButton-root {
    color: #ffffff;
  }
  right: 53px;
  top: 1.4rem;
  width: 174px;
  height: 36px;
  background: #cc0000;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
}

//Create Project ======================
.project-card-created {
  display: flex;
  flex-direction: row;
}
// for adding radio button
.project-modal {
  height: 189px;
  width: 400px;
  padding: 1rem;
  form {
    display: flex;
    align-items: baseline;
    .MuiFormControlLabel-root {
      margin-left: 0;
    }
  }
  .MuiIconButton-root {
    padding: 0;
  }
  .main-contain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
    }
  }
  form {
    .MuiFormControl-root {
      width: 100%;
      margin-top: 0.8rem;
    }
  }
  .create-project {
    margin-top: 17px;
    width: 67px;
    height: 36px;
    background: #cc0000;
    border-radius: 4px;
    color: white;
    position: absolute;
    text-transform: capitalize;
    bottom: 12px;
    right: 1rem;
  }
}

//Project Card and landing pages -----------------
.loader-linear {
  width: 100%;
  margin-top: 16vh;
}
.project-list-progress-bar {
  position: absolute;
  left: 50%;
  top: 46%;
  border-radius: 50%;
  box-shadow: 0px 2px 4px -2px rgb(51 51 51 / 16%),
    0px 4px 8px rgb(51 51 51 / 8%), 0px 0px 0px 1px rgb(51 51 51 / 4%);
  color: #cc0000 !important;
}
.project-card-container {
  margin-top: 3vh !important;
  padding: 0 3rem;
  gap: 0 2rem;
  overflow: scroll;
  height: 75vh;
  align-content: flex-start;
  @include scroll-bar();
  .project-card {
    padding: 12px;
    height: -moz-fit-content;
    height: 154px;
    width: 22rem;
    /* overflow-x: scroll; */
    margin-top: 1rem;
    background: white;
    border: 1px solid #d6d6d6;
    box-shadow: 0px 2px 4px -2px rgb(51 51 51 / 16%),
      0px 4px 8px rgb(51 51 51 / 8%), 0px 0px 0px 1px rgb(51 51 51 / 4%);
    border-radius: 8px;
  }
  .project-card-inner {
    @include scroll-bar();
    background: #d6e4ff;
    padding: 11px 9px;
    // overflow: scroll;
    height: 73px;
    .project-card-inner-structure {
      display: flex;
      align-items: baseline;
    }
  }
  .project-name-inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #888888;
  }
  .project-tcin-view-project {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      background: #cc0000;
      border-radius: 4px;
      color: #ffffff;
    }
  }
}

//No Result
.dashboard-noResult {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 2;
  transform: translate(-50%, 50%);
}

//side bar
.panelWrapOpen {
  z-index: 10;
  background-color: white;
  position: fixed;
  top: 17.8rem;
  bottom: 0;
  right: 0;
  width: 650px;
  transition: 0.3s ease-out;
  border-left: 10px solid #eeeeee;
  .MuiIconButton-root {
    margin: 1.5rem 0 0 0.2rem;
  }
}
.taskPanel {
  position: absolute;
  top: 3.6rem;
  bottom: 0;
  padding: 1rem 39px 0 3rem;
  width: 100%;
  overflow: auto;
}

.typo-tcin {
  font-weight: 700 !important;
  font-size: 14px !important ;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  opacity: 0.87;
  color: #366cd9;
  margin-bottom: 2rem !important;
}

.panel-second {
  margin-top: 1rem;
  border-bottom: 1px solid rgba(179, 179, 179, 1);
}
.upload-modal-section {
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 0 0 1rem 0;
  border-bottom: 1px solid rgba(179, 179, 179, 1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.status-changes {
  border-radius: 36px;
  padding: 8px;
  text-align: center;
  color: black;
  font-size: 12px;
}
.panel-second-typo {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.comments-section {
  margin-top: 1rem;
  form {
    .MuiInputBase-formControl {
      margin-top: 1.2rem;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .MuiButton-root {
    padding: 7.5px 12px;
    margin-bottom: 3rem;
    color: #3e72db;
    border: 1px solid #3e72db;
    border-radius: 4px;
  }
  .comment-cancel {
    margin-left: 1rem;
  }
}

//Comment section
.comment-section {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 17px;
  .comment-section-displayName {
    font-weight: 600;
  }
  .added-comment {
    color: #888888;
    padding: 1rem 0;
    background: #eeeeee;
    width: fit-content;
  }
}

//AddTcin
.sample-link:link {
  color: #1a0dab;
}

.sample-link:visited {
  color: #1a0dab;
}
.service-type-box {
  height: 85px;
  width: 360px;
  padding: 0 1rem;
  margin: 1rem;
  border-bottom: 1px solid #b1b1b1;
  .service-type-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.service-type-box:last-child {
  border-bottom: none;
}
.Add-Tcin-modal {
  width: 805px;
  .upload-view-link {
    padding-bottom: 10px;
  }
  .main-contain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    h6 {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
    }
  }
  .upload-Button {
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    height: 90px;
    label {
      img {
        margin-right: 0.5rem;
      }
      border: 1px dashed #8b8f98;
      color: #000;
      background-color: #fff;
      padding: 0.9rem 0.5rem 0 0.5rem;
      font-family: sans-serif;
      border-radius: 0.3rem;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      span {
        margin-left: 5px;
        font-size: 16px;
        font-weight: 500;
      }
      p {
        margin-top: 8px;
        font-size: 12px;
        color: #676b74;
      }
    }
  }
  .proceed-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .upload-file-Names {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 51px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 0px 0px;
    margin-top: 17px;
    font-weight: bold;
  }
  .btn-upload {
    margin-top: 1rem;
    position: absolute;
    bottom: 10px;
    left: 15px;
    min-width: 46%;
    text-transform: capitalize;
    background: rgba(0, 0, 0, 0.12);
  }
  .btn-active {
    background-color: #cc0000;
    color: white;
    margin-top: 0.5rem;
    &:hover {
      color: #cc0000;
    }
  }

  .tcin-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.6rem 0;
    .success {
      color: #008300;
    }
    .fail {
      color: #cc0000;
    }
  }
  .download-tcin-list {
    flex-direction: column;
  }

  .file-count-track {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }
  .result-tcin-report {
    display: flex;
    width: 100%;
    margin: 1rem 0 2rem 0;
    .fail-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .upload-with-icon {
    display: flex;
    margin-right: 0.4rem;
    .upload-complete {
      color: #008300;
      margin-right: 0.5rem;
    }
  }
}

// Assets
.Add-Asset-Modal {
  width: 540px;
  padding: 1rem;
  .main-contain {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: unset;
  }
  .Upload-assets {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    .MuiSvgIcon-root {
      margin-right: 0.5rem;
    }
  }
  .upload-assets {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Asset-upload {
      position: static;
    }
  }
  .upload-assets-green {
    display: flex;
    justify-content: space-between;
    width: 8rem;
  }
  .upload-history-btn {
    min-width: 60px;
    font-size: 12px;
    height: 36px;
    padding: 0.4rem;
    position: relative;
  }
  .upload-file-btn {
    position: absolute;
    left: 2rem;
    z-index: 10;
    width: 100px;
    background: #fff;
    padding: 0.5rem;
  }

  .upload-complete-green {
    color: #3e72db;
  }

  .folder-upload {
    padding: 0.6rem;
  }
  .is-drag-active {
    background-color: #ac0000;
    animation-name: stretch;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    cursor: pointer;
  }
  .view-format {
    padding: 0;
    text-decoration: underline;
    text-transform: capitalize;
  }
  @keyframes stretch {
    0% {
      background-color: #e6e6e6;
    }
    50% {
      background-color: #cc0000;
    }
    100% {
      background-color: #ffffff;
    }
  }
}
.contain-file-upload-list {
  height: 149px;
  overflow: scroll;
  @include scroll-bar();
}
.list-folder-item {
  padding: 0.9rem;
  li {
    text-decoration: underline;
    color: #366cd9;
    cursor: pointer;
  }
}
.container {
  cursor: pointer;
  height: 95px;
  background: #f0f0f0;
  border: 2px dashed #d6d6d6;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  letter-spacing: 0.1px;
  .upload-assets-inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//Pagination
.pagination-project {
  display: flex;
  justify-content: flex-start;
  padding: 5px 2rem;
}

//download file
.download-file {
  .MuiFab-root {
    width: 43px;
    height: 43px;
  }
}

.download-file-img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

// Nbr upload enhancement
.response-container {
  padding: 8px 0px;
  margin-top: 5px;
  border-width: 0.5px 0px;
  border-style: solid;
  border-color: #888888;
  .inline-response-container {
    display: flex;
    justify-content: space-between;
    p {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
.upload-history-list {
  display: none;
}

.animate-upload-history {
  animation: upload-history 0.5s;
  overflow: scroll !important;
  height: 100px;
  margin-top: 0.5rem;
}

.upload-history-item {
  border-bottom: 1px solid #888888;
}
.btn-upload-history {
  text-transform: none !important;
  margin-left: -14px !important;
}

.validation-message {
  padding: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  margin-top: 15px;
}

.bold-text {
  font-weight: 800;
}
.validation-error {
  color: #cc0000;
  background: rgba(172, 0, 0, 0.08);
}

.validation-success {
  color: #3b873e;
  background: rgba(76, 175, 80, 0.08);
}

.validation-warning {
  color: rgba(199, 119, 0, 1);
  background: rgba(237, 108, 2, 0.08);
}

.success-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(33, 150, 243, 0.08);
  height: 48px;
  padding: 0px 8px;
  margin-top: 18px;
}

.download-link {
  font-weight: 400;
  margin-top: 10px;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.no-file {
  width: 150px;
}

.file-available {
  width: 130px;
}

.pointer {
  cursor: pointer;
}

.norm-text {
  color: #000000;
}

.primary-button {
  background: #cc0000;
  border-radius: 4px;
  color: #ffffff;
  text-transform: none;
}

.header-project-status-label {
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  top: 6px;
  left: 64px;
}

.nbr-header {
  position: fixed;
  height: 70px;
  width: 100%;
  top: 85px;
  z-index: 101;
  background-color: #fff;
  box-shadow: 0px 1px 4px -1px rgb(0 0 0 / 20%),
    0px 2px 5px 0px rgb(0 0 0 / 14%), 0px 3px 10px 0px rgb(0 0 0 / 12%);
}

@keyframes upload-history {
  0% {
    height: 0px;
  }
  75% {
    height: 100px;
  }
  100% {
    height: 200px;
  }
}
